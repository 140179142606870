import { FC } from 'react';
import { GlobalStyles } from './styles/GlobalStyles';
import {ChakraBaseProvider, extendBaseTheme, theme as chakraTheme} from "@chakra-ui/react";

const { Button } = chakraTheme.components

const theme = extendBaseTheme({
    components: {
        Button,
    },
});
const App: FC = () => {


  return ( <ChakraBaseProvider theme={theme}>
      <GlobalStyles />
      <>Quiz app</>
    </ChakraBaseProvider>);
};

export default App;
